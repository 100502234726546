import { Controller } from "@hotwired/stimulus";
import { formatDuration } from "../src/formatDuration";

export default class BillCountdownController extends Controller {

  connect() {
    this.timer = null;

    const dataset = this.element.dataset;
    this.billId = dataset.billId
    this.duration = dataset.durationSeconds;
    this.text = dataset.text;
    this.redirectUrl = dataset.redirectUrl;

    const _self = this;

    this.subscription = App.cable.subscriptions.create({ channel: "BillTimerChannel", bill_id: this.billId }, {
      connected: () => {
        _self.start();
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
      },

      disconnected: () => {
        _self.stop();
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
      },

      received: (data) => {
        if (data.command === 'update_timer') {
          const duration = parseInt(data.order_left_duration_seconds);
          _self.duration = Math.max(duration, 0)
          _self.start();
        }

        if (data.command === 'redirect') {
          window.location.href = this.redirectUrl || '/';
        }
      }
    });
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      this.subscription.send({ command: 'get_timer' });
    }
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  start() {
    this.stop();
    this.updateText();

    if (this.duration <= 0) {
      this.disableBill();
      this.stop();
      console.log('A')
    } else {
      this.timer = setInterval(() => {
        if (this.duration <= 0) {
          console.log('B')
          this.disableBill();
          this.stop();
        } else {
          this.duration--;
          this.updateText();

          if (this.duration <= 0) {
            console.log('C')
            this.disableBill();
            this.stop();
          }
        }
      }, 1000);
    }
  }

  disableBill() {
    function createOverlay() {
      const disabledDiv = document.createElement('div');
      disabledDiv.classList.add('disabled_window');
      return disabledDiv
    }

    const amendmentBlock = document.getElementById('bookings-block');

    if (amendmentBlock) {
      amendmentBlock.insertBefore(createOverlay(), amendmentBlock.firstChild);

      const addSessionsBlock = document.getElementById('add_sessions_block');

      if (addSessionsBlock.hasChildNodes()) {
        const parent2Block = document.getElementById('add-sessions-block');
        parent2Block.insertBefore(createOverlay(), parent2Block.firstChild);
      }
    }

    const checkoutBox = document.getElementById('checkout-box');

    if (checkoutBox) {
      checkoutBox.insertBefore(createOverlay(), checkoutBox.firstChild);
    }
  }

  updateText() {
    this.element.innerHTML = this.prepareText();
    this.element.classList.remove('invisible');
  }

  prepareText() {
    let text = this.text;
    if (!text) text = 'You have #time to complete this stage of the booking process'

    return text.replace('#time', formatDuration(this.duration * 1000));
  }

  restart() {
    this.stop();
    this.start();
  }

  stop() {
    if (this.timer) clearInterval(this.timer);
  }
}

